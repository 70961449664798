"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createWebPushSubscription = exports.sendNotification = exports.requestPermissionForNotification = void 0;
function requestPermissionForNotification() {
    return __awaiter(this, void 0, void 0, function* () {
        if (Notification.permission !== 'denied') {
            const permission = yield Notification.requestPermission();
            console.info('Notification permission:', permission);
        }
    });
}
exports.requestPermissionForNotification = requestPermissionForNotification;
function sendNotification(title, body) {
    if (Notification.permission === "granted" && document.hidden) {
        var notification = new Notification(title, {
            body,
        });
        notification.onclick = function () {
            window.focus();
        };
    }
}
exports.sendNotification = sendNotification;
function createWebPushSubscription(publicKey) {
    return __awaiter(this, void 0, void 0, function* () {
        const registration = yield navigator.serviceWorker.ready;
        let subscription = yield registration.pushManager.getSubscription();
        if (subscription) {
            console.warn('subscription already exists, unsubscribing');
            yield subscription.unsubscribe();
        }
        console.log('creating subscription');
        subscription = yield registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array(publicKey)
        });
        console.log('subscription=', JSON.stringify(subscription));
        const payload = JSON.stringify(subscription);
        console.log('payload=', payload);
        return payload;
    });
}
exports.createWebPushSubscription = createWebPushSubscription;
function urlBase64ToUint8Array(base64String) {
    var padding = '='.repeat((4 - base64String.length % 4) % 4);
    var base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/');
    var rawData = window.atob(base64);
    var outputArray = new Uint8Array(rawData.length);
    for (var i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}
