"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StateMachine = void 0;
class StateMachine {
    constructor(context, name) {
        this.ticker = 0;
        this.config = {};
        this.states = new Map();
        this.isSwitchingState = false;
        this.stateQueue = [];
        this.context = context;
        this.name = name !== null && name !== void 0 ? name : 'fsm';
    }
    getPreviousState() {
        if (!this.previousState) {
            return '';
        }
        return this.previousState.name;
    }
    isCurrentState(name) {
        if (!this.currentState) {
            return false;
        }
        return this.currentState.name === name;
    }
    addState(name, config) {
        var _a, _b, _c;
        this.states.set(name, {
            name,
            onEnter: (_a = config === null || config === void 0 ? void 0 : config.onEnter) === null || _a === void 0 ? void 0 : _a.bind(this.context),
            onUpdate: (_b = config === null || config === void 0 ? void 0 : config.onUpdate) === null || _b === void 0 ? void 0 : _b.bind(this.context),
            onExit: (_c = config === null || config === void 0 ? void 0 : config.onExit) === null || _c === void 0 ? void 0 : _c.bind(this.context),
        });
        return this;
    }
    setState(name, config) {
        var _a, _b;
        if (!this.states.has(name)) {
            console.warn(`unable to set state=${name}`);
            return;
        }
        if (this.isSwitchingState) {
            this.stateQueue.push(name);
            return;
        }
        this.isSwitchingState = true;
        if ((_a = this.currentState) === null || _a === void 0 ? void 0 : _a.onExit) {
            this.currentState.onExit(this.config);
        }
        this.previousState = this.currentState;
        this.currentState = this.states.get(name);
        this.config = config;
        if (this.currentState) {
            this.ticker = 0;
            if ((_b = this.currentState) === null || _b === void 0 ? void 0 : _b.onEnter) {
                this.currentState.onEnter(this.config);
            }
        }
        this.isSwitchingState = false;
        return this;
    }
    update(dt) {
        var _a;
        if (this.stateQueue.length > 0) {
            const name = this.stateQueue.shift();
            this.setState(name);
            return;
        }
        if ((_a = this.currentState) === null || _a === void 0 ? void 0 : _a.onUpdate) {
            this.currentState.onUpdate(dt, this.ticker++, this.config);
        }
    }
}
exports.StateMachine = StateMachine;
