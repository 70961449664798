"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sendSMSInvite = exports.addImage = exports.addText = exports.resizeToScreen = void 0;
function resizeToScreen(scene, addListener, maxWidth, maxHeight) {
    const resize = () => {
        scene.setScreenSize(Math.min(maxWidth, document.documentElement.clientWidth), Math.min(maxHeight, document.documentElement.clientHeight));
    };
    if (addListener) {
        window.addEventListener('resize', resize);
    }
    //if the screen size is different than the scale size, resize
    if (window.innerWidth != scene.scale.width || window.innerWidth != scene.scale.height) {
        resize();
    }
}
exports.resizeToScreen = resizeToScreen;
function addText(scene, txt, x, y, config, cb) {
    const gameText = scene.add.text(x, y, txt, {
        fontSize: config.size || '32px',
        fontFamily: config.font || 'Arial',
        color: config.color || '#ffffff',
        align: config.align || 'center'
    })
        .setOrigin(config.origin || config.originX || 0.5, config.origin || config.originY || 0.5);
    if (cb) {
        gameText.setInteractive();
        gameText.on('pointerdown', cb);
    }
    return gameText;
}
exports.addText = addText;
function addImage(scene, frame, x, y, config, cb) {
    const image = scene.add.image(x, y, frame);
    image.setScale(config.scale || 1);
    image.setOrigin(config.origin || config.originX || 0.5, config.origin || config.originY || 0.5);
    if (cb) {
        image.setInteractive();
        image.on('pointerdown', cb);
    }
    return image;
}
exports.addImage = addImage;
function sendSMSInvite(url, roomId) {
    const inviteUrl = `${url}?invite=${roomId}`;
    var smsUri = 'sms:?body=' + encodeURIComponent(`I challenge you at ${inviteUrl}`);
    // Open the messaging app with the default message
    window.open(smsUri, '_blank');
}
exports.sendSMSInvite = sendSMSInvite;
