"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ServerMessages = void 0;
var ServerMessages;
(function (ServerMessages) {
    ServerMessages[ServerMessages["SendMessage"] = 0] = "SendMessage";
    ServerMessages[ServerMessages["MoveMade"] = 1] = "MoveMade";
    ServerMessages[ServerMessages["Restart"] = 2] = "Restart";
    ServerMessages[ServerMessages["SetPublicKey"] = 3] = "SetPublicKey";
    ServerMessages[ServerMessages["addSubscription"] = 4] = "addSubscription";
    ServerMessages[ServerMessages["removeSubscription"] = 5] = "removeSubscription";
})(ServerMessages || (exports.ServerMessages = ServerMessages = {}));
