"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientMessages = void 0;
var ClientMessages;
(function (ClientMessages) {
    ClientMessages[ClientMessages["SendMessage"] = 0] = "SendMessage";
    ClientMessages[ClientMessages["MakeMove"] = 1] = "MakeMove";
    ClientMessages[ClientMessages["Rematch"] = 2] = "Rematch";
    ClientMessages[ClientMessages["SetSubscription"] = 3] = "SetSubscription";
})(ClientMessages || (exports.ClientMessages = ClientMessages = {}));
